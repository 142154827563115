import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeColors = {
    yellowMain: '#FBCC30',
    green: '#4CAF50',
    blue: {
        main: '#315FA4',
        light: '#5E89D0',
        dark: '#1B3A75',
        contrastText: '#ffffff',
        50: '#E7EEF7',
        100: '#C3D4EA',
        200: '#9BB8DD',
        300: '#739CD0',
        400: '#557DC6',
        500: '#315FA4', // main
        600: '#2B5593',
        700: '#244982',
        800: '#1D3D71',
        900: '#162F54',
    },
    black: '#000000',
};

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: themeColors.yellowMain,
            contrastText: themeColors.black,
        },
        secondary: themeColors.blue,
    },
    components: {
        //     MuiOutlinedInput: {
        //         styleOverrides: {
        //             root: {
        //                 '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
        //                     {
        //                         borderColor: '#283593',
        //                     },
        //                 '&:not(.Mui-disabled):hover': {
        //                     '.MuiInputLabel-root': {
        //                         color: '#283593',
        //                     },
        //                 },
        //             },
        //         },
        //     },
        // MuiButton: {
        //     styleOverrides: {
        //         root: {
        //             maxWidth: '500px',
        //             // '&:not(.Mui-disabled):hover': {
        //             //     borderColor: '#283593',
        //             //     '& .MuiButton-label': {
        //             //         color: '#283593',
        //             //     },
        //             // },
        //         },
        //     },
        // },
    },
};

export const theme = createTheme(themeOptions);

export const buttonSx = {
    margin: 'auto',
    minWidth: {
        xs: '200px',
        sm: '400px',
    },
};
