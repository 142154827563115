import axios from 'axios';
import { ErrorHandler } from '../defaultApiErrorHandler';

export class FormNotFoundError extends Error {
    constructor(message: string = 'Form not found or expired') {
        super(message);
        this.name = 'FormNotFoundError';
        // errorEvents.emit('form_not_found');
    }
}

export class FormValidationError extends Error {
    constructor(message: string = 'Form validation error') {
        super(message);
        this.name = 'FormValidationError';
        // errorEvents.emit('error_occured');
    }
}

export class FormErrorHandler implements ErrorHandler {
    handleError(error: unknown): never {
        if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            const message = error.response?.data?.message;

            if (status === 404) {
                localStorage.removeItem('encryptedSimpleFormId');
                throw new FormNotFoundError(message);
            }

            if (status === 422) {
                localStorage.removeItem('encryptedSimpleFormId');
                throw new FormValidationError(message);
            }
        }
        throw error;
    }
}
