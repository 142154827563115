import { useAuth0 } from '@auth0/auth0-react';
import { Box, SxProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLogger } from '../../hooks/useLogger';
import { AuthSkeleton } from '../skeleton';
import { EmailCodeLoginButton } from './emailCodeLoginButton';
import { GoogleLoginButton } from './googleLoginButton';
import { HustroContinueButton } from './hustroContinueButton';
import { HustroLoginButton } from './hustroLoginButton';
import fullLogo from '../../assets/fullLogo.png';
import { buttonSx } from '../../theme';
import { RedirectToSimpleFormId } from '../redirectToSimpleFormId';

export interface LoginButtonProps {
    sx?: SxProps;
    onClick?: () => void;
}
export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
    const {
        getAccessTokenSilently,
        getIdTokenClaims,
        user,
        isAuthenticated,
        isLoading,
    } = useAuth0();
    const intl = useIntl();
    const logger = useLogger();
    const [
        isUserAuthenticatedWithHustroApp,
        setIsUserAuthenticatedWithHustroApp,
    ] = useState(false);
    const [isTokenLoading, setIsTokenLoading] = useState(false);

    // Handle URL error parameters
    useEffect(() => {
        if (window.location.search.includes('error')) {
            const urlParams = new URLSearchParams(window.location.search);
            const errorDescriptionFromUrl = urlParams.get('error_description');
            const errorMessageFromUrl = urlParams.get('error');

            logger.error(
                'Error caught by AuthWrapper',
                {
                    message: errorMessageFromUrl || '',
                    name: 'Unauthorized',
                },
                {
                    timestamp: Date.now(),
                    description: errorDescriptionFromUrl,
                },
            );
        }
    }, [logger]);

    // Handle authentication and token management
    useEffect(() => {
        const getToken = async () => {
            setIsTokenLoading(true);
            try {
                const token = await getAccessTokenSilently();
                if (token) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('email', user?.email || ' ');
                }
            } catch (e: unknown) {
                //@ts-expect-error unknown auth0 error type
                if (e?.error === 'login_required') {
                    return;
                }
                //@ts-expect-error unknown auth0 error type
                if (e.error === 'consent_required') {
                    setIsUserAuthenticatedWithHustroApp(true);
                } else {
                    //@ts-expect-error unknown auth0 error type
                    logger.error('Error getting token', e);
                }
            } finally {
                setIsTokenLoading(false);
            }
        };

        getToken();
    }, [
        getAccessTokenSilently,
        getIdTokenClaims,
        user,
        logger,
        isAuthenticated,
    ]);

    if (isLoading || isTokenLoading) {
        return <AuthSkeleton sx={{ mt: 12, mx: 'auto' }} />;
    }
    if (!isAuthenticated) {
        return (
            <Box
                sx={{ mt: 6, mx: 4 }}
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <RedirectToSimpleFormId />
                <img
                    style={{ maxWidth: '60%', margin: 'auto' }}
                    src={fullLogo}
                />
                <Typography sx={{ mt: 2, textAlign: 'center' }} variant="h4">
                    {intl.formatMessage({ id: 'login_page_welcome_message' })}
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{ mt: 4 }}
                >
                    {isUserAuthenticatedWithHustroApp && (
                        <HustroContinueButton sx={buttonSx} />
                    )}
                    <EmailCodeLoginButton sx={buttonSx} />
                    <GoogleLoginButton sx={buttonSx} />
                    {!isUserAuthenticatedWithHustroApp && (
                        <HustroLoginButton sx={buttonSx} />
                    )}
                </Box>
            </Box>
        );
    }
    return (
        <Box>
            <RedirectToSimpleFormId />
            {children}
        </Box>
    );
};
