import { RedirectLoginOptions } from '@auth0/auth0-react';

export function getRedirectLoginOptions(
    connection: 'google-oauth2' | 'email' | 'Username-Password-Authentication',
): RedirectLoginOptions {
    const encryptedSimpleFormId = window.location.pathname.split('/')[1];
    const state = btoa(
        JSON.stringify({
            encryptedSimpleFormId: encryptedSimpleFormId || null,
            timestamp: Date.now(),
        }),
    );
    return {
        authorizationParams: {
            prompt: 'login',
            redirect_uri: window.location.origin,
            connection,
            state,
        },
    };
}
