import { useAuth0 } from '@auth0/auth0-react';
import { Button, SxProps } from '@mui/material';
import { useIntl } from 'react-intl';
import hustro from '../../assets/hustro_color.jpg';

export const HustroContinueButton = ({ sx }: { sx?: SxProps }) => {
    const { loginWithRedirect } = useAuth0();

    function askForConsentExistingHustroUser() {
        loginWithRedirect({
            authorizationParams: {
                prompt: 'consent',
            },
        });
    }

    const intl = useIntl();

    return (
        <Button
            variant="outlined"
            color="secondary"
            sx={sx}
            size="large"
            onClick={askForConsentExistingHustroUser}
            startIcon={<img height="20px" src={hustro} />}
        >
            {intl.formatMessage({
                id: 'login_page_continue_with_hustro_account',
            })}
        </Button>
    );
};
