import { useAuth0 } from '@auth0/auth0-react';
import { Button, SxProps } from '@mui/material';
import { useIntl } from 'react-intl';
import google from '../../assets/chrome.svg';
import { getRedirectLoginOptions } from './getRedirectLoginOptions';

export const GoogleLoginButton = ({ sx }: { sx?: SxProps }) => {
    const { loginWithRedirect } = useAuth0();

    function loginWithGmail() {
        loginWithRedirect(getRedirectLoginOptions('google-oauth2'));
    }
    const intl = useIntl();

    return (
        <Button
            variant="outlined"
            color="secondary"
            size="large"
            sx={sx}
            startIcon={<img height="20px" src={google} />}
            // textColor="black"
            onClick={loginWithGmail}
        >
            {intl.formatMessage({ id: 'login_page_google_login' })}
        </Button>
    );
};
