import { EncryptedObjectIdString } from '../../types/common';
import { ProjectDto } from '../../types/dto/project';
import { buildPath, createResourceService } from '../resourceService';
import { ProjectsErrorHandler } from './errorHandler';

export const getProjects = createResourceService<
    { encryptedSimpleFormId: EncryptedObjectIdString },
    ProjectDto[]
>({
    getPath: ({ encryptedSimpleFormId }) =>
        buildPath(encryptedSimpleFormId, 'project'),
    errorHandler: new ProjectsErrorHandler(),
});
