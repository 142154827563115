type EventCallback = (message: string) => void;
const listeners: EventCallback[] = [];

export const errorEvents = {
    subscribe: (callback: EventCallback) => {
        listeners.push(callback);
        return () => {
            const index = listeners.indexOf(callback);
            if (index > -1) listeners.splice(index, 1);
        };
    },
    emit: (message: string) => {
        listeners.forEach((callback) => callback(message));
    },
    emitGenericError: () => {
        listeners.forEach((callback) => callback('error_occured'));
    },
};
