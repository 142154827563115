import ErrorIcon from '@mui/icons-material/Error';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLogger } from '../../hooks/useLogger';

export const ErrorPage = ({
    title,
    message,
}: {
    title: string;
    message: string;
}) => {
    const intl = useIntl();
    const logger = useLogger();

    logger.error(
        `Error Page was displayed to the user with title: ${title} and message: ${message}`,
    );
    const handleReturn = () => {
        window.location.href = '/';
    };
    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // p: 1,
            }}
        >
            <Container maxWidth="sm">
                <Paper
                    elevation={3}
                    sx={{
                        p: 3,
                        // backgroundColor: 'error.light',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <ErrorIcon color="error" sx={{ fontSize: 40 }} />
                    <Typography variant="h5" component="h2" color="error.dark">
                        {intl.formatMessage({ id: title })}
                    </Typography>
                    <Typography color="error.dark" align="center">
                        {intl.formatMessage({ id: message })}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<HomeIcon />}
                        onClick={handleReturn}
                        sx={{ mt: 2 }}
                    >
                        {intl.formatMessage({ id: 'return_home' })}
                    </Button>
                </Paper>
            </Container>
        </Box>
    );
};
