import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    FormNotFoundError,
    FormValidationError,
} from '../services/simpleForm/errorHandler';
import { getSimpleForm } from '../services/simpleForm/service';
import { EncryptedObjectIdString } from '../types/common';
import { SimpleFormDto } from '../types/dto/simpleForm';
import { useResource } from './useResource';

const simpleFormByEncryptedIdMap = new Map<
    EncryptedObjectIdString,
    SimpleFormDto
>();

export const useSimpleForm = () => {
    const navigate = useNavigate();
    const { fetchResource } = useResource<
        {
            encryptedSimpleFormId: EncryptedObjectIdString;
        },
        SimpleFormDto
    >({
        fetchFn: getSimpleForm,
        cacheMap: simpleFormByEncryptedIdMap,
        getCacheKey: ({ encryptedSimpleFormId }) => encryptedSimpleFormId,
    });

    const fetchSimpleForm = useCallback(
        async (params: { encryptedSimpleFormId: EncryptedObjectIdString }) => {
            try {
                return await fetchResource(params);
            } catch (error) {
                if (error instanceof FormNotFoundError) {
                    navigate('/error/form-not-found');
                } else if (error instanceof FormValidationError) {
                    navigate('/error/invalid-url');
                }
                throw error;
            }
        },
        [fetchResource, navigate],
    );

    return { fetchSimpleForm };
};
