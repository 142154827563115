import {
    authorizedApiClient,
    simpleFormApiClient,
    unauthorizedApiClient,
} from '../api/apiClient';
import { ObjectIdString } from '../types/common';
import { DefaultErrorHandler, ErrorHandler } from './defaultApiErrorHandler';

type PathSegments = (string | ObjectIdString)[];
export const getAuthHeader = (token: string) => ({
    Authorization: `Bearer ${token}`,
});
export const buildPath = (
    encryptedSimpleFormId: string,
    ...segments: PathSegments
): string => {
    return `/${encryptedSimpleFormId}/${segments.join('/')}`;
};

// const handleApiError = (error: unknown): never => {
//     if (axios.isAxiosError(error)) {
//         const status = error.response?.status;
//         if (status === 422) {
//             errorEvents.emit('error_occured');
//             localStorage.removeItem('encryptedSimpleFormId');
//             throw new Error(
//                 `Validation error: ${error.response?.data?.message || 'Invalid data'}`,
//             );
//         }
//     }
//     throw error;
// };

type HttpMethod = 'get' | 'post' | 'put' | 'delete';

export enum ApiClient {
    SimpleForm,
    Unauthorized,
    Authorized,
}

export const clientMap = {
    [ApiClient.SimpleForm]: simpleFormApiClient,
    [ApiClient.Unauthorized]: unauthorizedApiClient,
    [ApiClient.Authorized]: authorizedApiClient,
};

interface ResourceEndpointConfig<TParams, TResponse> {
    getPath: (params: TParams) => string;
    method?: HttpMethod;
    getHeaders?: (token: string) => Record<string, string>;
    getParams?: (params: TParams) => Record<string, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapResponse?: (response: any) => TResponse;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBody?: (params: TParams) => Record<string, any>;
    onError?: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: any,
        token: string,
        params: TParams,
    ) => Promise<TResponse>;
    client?: ApiClient;
    errorHandler?: ErrorHandler;
}

export const createResourceService = <TParams, TResponse>(
    config: ResourceEndpointConfig<TParams, TResponse>,
) => {
    const errorHandler = config.errorHandler || new DefaultErrorHandler();

    return async (token: string, params: TParams): Promise<TResponse> => {
        try {
            const client = clientMap[config.client ?? ApiClient.SimpleForm];
            const response = await client.request({
                method: config.method ?? 'get',
                url: config.getPath(params),
                headers: config.getHeaders?.(token) ?? getAuthHeader(token),
                params: config.getParams?.(params),
                data: config.getBody?.(params),
            });
            return config.mapResponse
                ? config.mapResponse(response.data)
                : response.data;
        } catch (error) {
            if (config.onError) {
                return config.onError(error, token, params);
            }
            return errorHandler.handleError(error);
        }
    };
};
