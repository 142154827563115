import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { UserDataInOrganization } from '../components/organization/card';
import { FormSkeleton } from '../components/skeleton';
import { useMe } from '../hooks/useMe';
import {
    identifyUserAndPage,
    PageName,
} from '../utils/locale/identifyUserAndPage';

export const UserForm = () => {
    const { me } = useMe();
    const intl = useIntl();
    if (!me) {
        return <FormSkeleton />;
    }
    identifyUserAndPage(me._id, PageName.personalData, me.email);

    const textSx = {
        padding: 3,
        mt: 8,
        display: 'flex',
        justifyContent: 'center',
    };
    if (me?.basePermissions?.role === 'superadmin') {
        return (
            <Box sx={textSx}>
                <Typography>Not supported for superadmin</Typography>
            </Box>
        );
    }
    if (me.personalData.length === 0) {
        return (
            <Box sx={textSx}>
                <Typography>
                    {intl.formatMessage({ id: 'empty_personal_data_view' })}
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={{ padding: 3, mt: 6 }}>
            <Grid container spacing={2}>
                {me?.personalData.map(({ label, phone, encryptedClientId }) => (
                    <Grid item xs={12} md={6} key={encryptedClientId}>
                        <UserDataInOrganization
                            label={label}
                            phone={phone}
                            encryptedClientId={encryptedClientId}
                            userId={me._id}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
