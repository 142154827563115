import { EncryptedObjectIdString } from '../types/common';

export function extractEncryptedSimpleFormIdFromPath() {
    const pathParts = window.location.pathname.split('/');
    return pathParts[1] || null;
}

export function isEncryptedObjectIdString(
    input: unknown,
): input is EncryptedObjectIdString {
    return typeof input === 'string' && input.length === 22;
}

export function setSimpleFormId(
    encryptedSimpleFormId: EncryptedObjectIdString,
) {
    const existing = localStorage.getItem('encryptedSimpleFormId');
    if (existing !== encryptedSimpleFormId) {
        localStorage.setItem('encryptedSimpleFormId', encryptedSimpleFormId);
    }
}
export function getSimpleFormIdFromLocalStorage():
    | EncryptedObjectIdString
    | undefined {
    const hash = localStorage.getItem('encryptedSimpleFormId');
    if (isEncryptedObjectIdString(hash)) {
        return hash;
    }
}
