import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    extractEncryptedSimpleFormIdFromPath,
    getSimpleFormIdFromLocalStorage,
    isEncryptedObjectIdString,
    setSimpleFormId,
} from '../utils/simpleFormId';

export function RedirectToSimpleFormId() {
    const navigate = useNavigate();

    useEffect(() => {
        const existingEncryptedSimpleFormIdInPath: string | null =
            extractEncryptedSimpleFormIdFromPath();
        if (isEncryptedObjectIdString(existingEncryptedSimpleFormIdInPath)) {
            return setSimpleFormId(existingEncryptedSimpleFormIdInPath);
        }
        const isBaseUrl = window.location.pathname === '/';
        if (isBaseUrl) {
            const encryptedSimpleFormIdToRedirectUserTo =
                getSimpleFormIdFromLocalStorage();
            if (encryptedSimpleFormIdToRedirectUserTo) {
                return navigate(`/${encryptedSimpleFormIdToRedirectUserTo}`, {
                    replace: true,
                });
            }
            if (isBaseUrl) {
                navigate('/formCode');
            }
        }
    }, [navigate]);

    return null;
}
