import type { Scope } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { useLogger } from '../../hooks/useLogger';
import { ErrorPage } from './errorPage';

export const CustomErrorBoundary = ({ children }: { children: ReactNode }) => {
    const logger = useLogger();

    return (
        <Sentry.ErrorBoundary
            beforeCapture={(
                _: Scope,
                error: unknown,
                componentStack?: string,
            ) => {
                const userEmail = localStorage.getItem('email');
                const userId = localStorage.getItem('id');
                console.log(error);
                console.log(componentStack);
                if (error instanceof Error) {
                    logger.error('Error caught by ErrorBoundary', error, {
                        errorInfo: { componentStack },
                        timestamp: Date.now(),
                        userEmail,
                        userId,
                    });
                } else {
                    logger.error(
                        `Error caught by ErrorBoundary: ${JSON.stringify(error)}`,
                        { name: '', message: '' },
                        {
                            errorInfo: { componentStack },
                            timestamp: Date.now(),
                            userEmail,
                            userId,
                        },
                    );
                }
            }}
            fallback={
                <ErrorPage title={'error_occured'} message={'unknown_error'} />
            }
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
