import { Stack, Skeleton, SxProps } from '@mui/material';

const RectSkeleton = ({ sx }: { sx?: SxProps }) => (
    <Skeleton
        variant="rectangular"
        sx={{ width: '80%', height: 50, mx: 'auto', ...sx }}
    />
);
export const FormSkeleton = () => (
    <Stack spacing={2} sx={{ position: 'absolute', mt: 8 }}>
        <RectSkeleton sx={{ alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center', height: 100 }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
    </Stack>
);
export const AuthSkeleton = ({ sx }: { sx?: SxProps }) => (
    <Stack spacing={3} sx={sx}>
        <RectSkeleton sx={{ height: 100, alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
        <RectSkeleton sx={{ alignSelf: 'center' }} />
    </Stack>
);
