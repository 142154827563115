import React, { useState, useEffect } from 'react';
import { Snackbar, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useIntl } from 'react-intl';

export const CookieToast = () => {
    const [open, setOpen] = useState(true);
    const intl = useIntl();

    useEffect(() => {
        const hasConsent = localStorage.getItem('cookieConsent');
        if (hasConsent) {
            setOpen(false);
        }
    }, []);

    const handleClose = (
        _: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        localStorage.setItem('cookieConsent', 'true');
    };

    const handleButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(false);
        localStorage.setItem('cookieConsent', 'true');
    };

    const action = (
        <>
            <Button color="primary" size="small" onClick={handleButtonClick}>
                {intl.formatMessage({ id: 'accept' })}
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleButtonClick}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={intl.formatMessage({ id: 'cookie_info' })}
            action={action}
            onClose={handleClose}
            sx={{
                width: '100%',
                '& .MuiSnackbarContent-root': {
                    width: {
                        xs: '95%',
                        sm: '90%',
                        md: '80%',
                    },
                    maxWidth: '800px',
                    margin: '0 auto',
                    padding: {
                        xs: '12px 16px',
                        sm: '14px 20px',
                    },
                },
                '& .MuiSnackbarContent-message': {
                    flex: '1 1 auto',
                    marginRight: '16px',
                },
                '& .MuiSnackbarContent-action': {
                    paddingLeft: '16px',
                    marginRight: '-8px',
                },
            }}
        />
    );
};
