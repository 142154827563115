import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectsValidationError } from "../services/project/errorHandler";
import { getProjects } from "../services/project/service";
import { FormNotFoundError } from "../services/simpleForm/errorHandler";
import { EncryptedObjectIdString } from "../types/common";
import { ProjectDto } from "../types/dto/project";
import { useResource } from "./useResource";
const projectsByEncryptedSimpleFormIdMap = new Map<
    EncryptedObjectIdString,
    ProjectDto[]
>();

export const useProjects = () => {
    const navigate = useNavigate();

    const { fetchResource } = useResource<
        {
            encryptedSimpleFormId: EncryptedObjectIdString;
        },
        ProjectDto[]
    >({
        fetchFn: getProjects,
        cacheMap: projectsByEncryptedSimpleFormIdMap,
        getCacheKey: ({ encryptedSimpleFormId }) => encryptedSimpleFormId,
    });

    const fetchProjects = useCallback(
        async (params: { encryptedSimpleFormId: EncryptedObjectIdString }) => {
            try {
                return await fetchResource(params);
            } catch (error) {
                if (error instanceof FormNotFoundError) {
                    navigate('/error/form-not-found');
                } else if (error instanceof ProjectsValidationError) {
                    navigate('/error/invalid-url');
                }
                throw error;
            }
        },
        [fetchResource, navigate],
    );

    return { fetchProjects };
};
