import { Auth0Provider } from '@auth0/auth0-react';
import { Box, ThemeProvider } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthWrapper } from './components/auth/authWrapper';
import { ErrorToast } from './components/error/errorToast';
import { CookieToast } from './components/fileUploader/cookieToast';
import { PatternedBackground } from './components/patternedBackground';
import { RedirectToSimpleFormId } from './components/redirectToSimpleFormId';
import { FormStepper } from './components/stepper';
import { TopBar } from './components/topBar';
import { getAuth0Config } from './config/auth0';
import { IntlProviderWrapper } from './contextProviders/IntlProviderWrapper';
import { WithGoogleAnalytics } from './hooks/useGoogleAnalytics';
import { theme } from './theme';
import { UrlParam } from './types/common';
import { getSumologicCollectorUrl } from './utils/environment';
import { LoggerProvider } from './utils/logger/loggerContext';
import { CodeForm } from './views/codeForm';
import { ErrorPage } from './views/error/errorPage';
import { FileForm } from './views/fileForm';
import { IssueFormView } from './views/issueFormView';
import { UserForm } from './views/userForm';
import { UserOnOrganizationForm } from './views/userOnOrganizationForm';
import { CustomErrorBoundary } from './views/error/customErrorBoundary';

function App() {
    const auth0Config = getAuth0Config();

    return (
        <LoggerProvider collectorUrl={getSumologicCollectorUrl()}>
            <IntlProviderWrapper>
                <ErrorToast />
                <CustomErrorBoundary>
                    <PatternedBackground>
                        <ThemeProvider theme={theme}>
                            <Auth0Provider
                                domain={auth0Config.domain}
                                clientId={auth0Config.clientId}
                                authorizationParams={
                                    auth0Config.authorizationParams
                                }
                            >
                                <Router>
                                    <WithGoogleAnalytics>
                                        <AuthWrapper>
                                            <TopBar />
                                            <Routes>
                                                <Route
                                                    path="/error/form-not-found"
                                                    element={
                                                        <ErrorPage
                                                            title={
                                                                'error_occured'
                                                            }
                                                            message={
                                                                'form_not_found'
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/error/invalid-url"
                                                    element={
                                                        <ErrorPage
                                                            title={
                                                                'error_occured'
                                                            }
                                                            message={
                                                                'invalid_url'
                                                            }
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path="/formCode"
                                                    element={<CodeForm />}
                                                />
                                                <Route
                                                    path="/user"
                                                    element={<UserForm />}
                                                />
                                                <Route
                                                    path={`/:${UrlParam.encryptedSimpleFormId}/project/:${UrlParam.projectId}/issue/:${UrlParam.issueId}/organization/:${UrlParam.encryptedClientId}/user`}
                                                    element={
                                                        <Box>
                                                            <FormStepper
                                                                activeStepIndex={
                                                                    2
                                                                }
                                                            />
                                                            <UserOnOrganizationForm />
                                                        </Box>
                                                    }
                                                />
                                                <Route
                                                    path={`/:${UrlParam.encryptedSimpleFormId}/project/:${UrlParam.projectId}/issue/:${UrlParam.issueId}`}
                                                    element={
                                                        <Box>
                                                            <FormStepper
                                                                activeStepIndex={
                                                                    1
                                                                }
                                                            />
                                                            <FileForm />
                                                        </Box>
                                                    }
                                                />
                                                <Route
                                                    path={`/:${UrlParam.encryptedSimpleFormId}`}
                                                    element={<IssueFormView />}
                                                />
                                                <Route
                                                    path={`/`}
                                                    element={
                                                        <RedirectToSimpleFormId />
                                                    }
                                                />
                                            </Routes>
                                        </AuthWrapper>
                                    </WithGoogleAnalytics>
                                </Router>
                            </Auth0Provider>
                            <CookieToast />
                        </ThemeProvider>
                    </PatternedBackground>
                </CustomErrorBoundary>
            </IntlProviderWrapper>
        </LoggerProvider>
    );
}

export default App;
