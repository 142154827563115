import axios from 'axios';
import { ErrorHandler } from '../defaultApiErrorHandler';
import { FormNotFoundError } from '../simpleForm/errorHandler';

export class ProjectsValidationError extends Error {
    constructor(message: string = 'Projects validation error') {
        super(message);
        this.name = 'ProjectsValidationError';
        // errorEvents.emit('error_occured');
    }
}

export class ProjectsErrorHandler implements ErrorHandler {
    handleError(error: unknown): never {
        if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            const message = error.response?.data?.message;

            if (status === 404) {
                localStorage.removeItem('encryptedSimpleProjectsId');
                throw new FormNotFoundError(message);
            }

            if (status === 422) {
                localStorage.removeItem('encryptedSimpleProjectsId');
                throw new ProjectsValidationError(message);
            }
        }
        throw error;
    }
}
