import axios from 'axios';
import { errorEvents } from '../components/error/errorEvents';

export class ResourceNotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ResourceNotFoundError';
        errorEvents.emit('error_occured');
    }
}

export class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ValidationError';
        errorEvents.emit('error_occured');
    }
}

export interface ErrorHandler {
    handleError: (error: unknown) => never;
}

export class DefaultErrorHandler implements ErrorHandler {
    handleError(error: unknown): never {
        if (axios.isAxiosError(error)) {
            const status = error.response?.status;
            const message =
                error.response?.data?.message || 'Unknown error occurred';

            if (status === 404) {
                throw new ResourceNotFoundError(message);
            }
            if (status === 422) {
                throw new ValidationError(message);
            }
        }
        throw error;
    }
}
